import cx from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import { PageContext } from '../../context/PageContext';
import { getBreadCrumbForPath } from '../../helpers/sitemap/getBreadCrumbForPath';
import { languages, LanguageType } from '../../languages';
import { SitemapItemType } from '../../queries/sitemap';

export type BreadcrumbProps = {
  path?: SitemapItemType[];
};

export const Breadcrumb = ({ path }: BreadcrumbProps) => {
  const { sitemap, preview } = React.useContext(PageContext);
  const router = useRouter();

  path =
    path ||
    getBreadCrumbForPath(router?.asPath, sitemap, router.locale as LanguageType);

  if (preview) {
    path = 'Path to page'.split(' ').map((label) => ({
      _id: '',
      _type: 'page.content',
      title: label,
      titles: languages.reduce(
        (acc, curr) => ({ ...acc, [curr.id]: label }),
        {},
      ) as SitemapItemType['titles'],
      path: '/',
      paths: languages.reduce(
        (acc, curr) => ({ ...acc, [curr.id]: `/${curr.id}` }),
        {},
      ) as SitemapItemType['paths'],
      _updatedAt: '',
    }));

    console.log(path);
  }

  if (!path?.length) return null;

  return (
    <nav aria-label="breadcrumbs" className="overflow-auto text-md">
      <ol
        itemScope
        itemType="https://schema.org/BreadcrumbList"
        className="flex font-normal"
      >
        {path?.map((item, index) => (
          <li
            key={item.path}
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
            className="whitespace-nowrap"
          >
            <Link href={item.path}>
              <a
                itemProp="item"
                aria-current={index === path.length - 1 ? 'location' : null}
                className={cx(
                  'opacity-75 underline-offset-2 hover:underline hover:opacity-100 transition-opacity text-current',
                  {
                    ['font-bold']: index === path.length - 1,
                  },
                )}
              >
                <span itemProp="name">{item.title}</span>
              </a>
            </Link>
            <meta itemProp="position" content={`${index + 1}`} />
            {index < path.length - 1 && <span className="px-2">&rsaquo;</span>}
          </li>
        ))}
      </ol>
    </nav>
  );
};
