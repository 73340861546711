import React from 'react';

import { baseLanguage, LanguageType } from '../languages';
import { ConfigType } from '../queries/config';
import { SitemapType, SitemapItemType } from '../queries/sitemap';

export const PageContext = React.createContext({
  preview: false,
  sitemap: [] as SitemapType,
  config: {} as ConfigType,
  sitemapItem: {} as SitemapItemType,
  locale: baseLanguage as LanguageType,
});
