import { LanguageType } from '../../languages';
import { SitemapItemType, SitemapType } from '../../queries/sitemap';

export const getItemForPath = (
  path: string,
  sitemap: SitemapType,
  locale: LanguageType,
): SitemapItemType | null => {
  return sitemap?.find((item) => item?.paths[locale] === path);
};
