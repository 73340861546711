import groq from 'groq';

import { HeroBasicProps } from '../heroes/HeroBasic';
import { COMPOSABLE_CARD_THEME_OPTIONS } from '../modules/CardGrid/ComposableCardOptions';
import { ImageType, OTHER_COUNTRY_CODE } from '../types';
import { ModuleSchemaName, SchemaName } from '../types.sanity';
import { buttonQuery, resolveIdHrefQuery } from './components/button';
import { getImageQuery, getRefImageQuery, imageQuery } from './components/image';
import { richTextQuery } from './components/richText';
import { staticFormQuery } from './components/staticForm';
import { getTranslationQuery } from './components/translations';
import { videoQuery } from './components/video';
import { jobQueryFields, jobsQuery } from './components/workable';
import { ConfigType } from './config';

export type PageType = {
  _type: SchemaName;
  _id: string;
  hero: HeroBasicProps;
  image?: ImageType;
  description?: string;
  title: string;
  hideNav?: boolean;
  hideFooter?: boolean;
  seo: ConfigType['seo'];
  modules: { _type: ModuleSchemaName; image?: ImageType }[]; // TODO: type all modules
  dialogs: {}[]; // TODO: type all dialogs
  locked?: boolean;
};

export const getPageQuery = (language) => groq`*[_id == $_id && _type == $_type][0]{
  _id,
  _type,
  "title": title.${language},
  hideNav,
  hideFooter,
  "locked": locked.${language},

  // article intro and image
  publishedAt,
  description,
  "image": ${imageQuery},

  // page seo
  "seo": seo.${language} {
    ...,
    "image": ${imageQuery}
  },

  // hero
  "hero": select(_type == 'page.preset' => modules, hero[language == "${language}"])[] {
    _type,
    _key,

    // Hero Basic
    _type == "hero.basic" => {
      title,
      eyebrow,
      visual {
        "image1": ${getImageQuery('image1')},
        "image2": ${getImageQuery('image2')},
        colors
      },
      showLozenges,
      breakOutImage,
      text[] ${richTextQuery},
      buttons[] ${buttonQuery},
    },

    // Article Hero
    _type == "hero.article" => {
      eyebrow,
      title,
      intro[] ${richTextQuery},
      "date": select(
        defined(^.startDate) || defined(^.endDate) => [^.startDate, ^.endDate],
        defined(^.publishedAt) => ^.publishedAt,
        ^._updatedAt
      ),
      
      "source": ^.source,
      "tags": ^.tags[]-> {
        "title": title.${language},
        "slug": slug.${language}.current
      },
      "countries": ^.countries[]-> {
        "title": title.${language},
        "slug": slug.${language}.current
      },
      "contacts": ^.contacts[]-> {
        name,
        "position": position.${language},
        email,
        phone,
        "image": ${imageQuery},
      },
      "PressReleaseLogoField": {
        "src": ^.logo->image.asset->url,
        "width": ^.logo->image.asset->metadata.dimensions.width,
        "height": ^.logo->image.asset->metadata.dimensions.height
      },      
    },
  }[0],

  // modules
  "modules": modules[language == "${language}" && !(_type in path("studio.*"))] {
    _key,
    _type,
    decorations[] {
      ...,
      "image": ${imageQuery},
    },
    theme,
  

    // rich text
    _type == "module.richtext" => {
      eyebrow,
      title,
      content[] ${richTextQuery}
    },
  
    // Breadcrumb
    _type == "module.breadcrumb" => {},
  
    // Card Slider
    _type == "module.cardgrid" => {
      eyebrow,
      title,
      intro[] ${richTextQuery},
      slider,
      gap,
      columns,
      buttons[] ${buttonQuery},
      feed,
      "items": select(

        // department cards
        feed.type == 'department' => *[_type == 'page.department' && !(_id in path("drafts.**"))] {
          "type": 'card.composable',
          _id,
          _updatedAt,
          "_key": _id,
          "themeName": "${COMPOSABLE_CARD_THEME_OPTIONS.department}",
          "title": title.${language},
          "text": description.${language},
          "image": ${imageQuery},
          "buttons": [{ 
            "label": ${getTranslationQuery('read_more', language)},
            "variant": 'secondary',
            "href": ${resolveIdHrefQuery}
          }]
        } | order(title asc),

        // people cards
        feed.type == 'person' => feed.items[]-> {
          "type": 'card.composable',
          _id,
          _updatedAt,
          _createdAt,
          "_key": _id,
          "themeName": "${COMPOSABLE_CARD_THEME_OPTIONS.person}",
          "title": name,
          "subtitle": position.${language},
          "text": description.${language},
          "image": ${imageQuery},
        },
        
        // article cards
        (
          feed.type == 'pressrelease'
          || feed.type == 'mediacoverage'
          || feed.type == 'event'
        ) => *[
          _type match ('page.' + ^.feed.type)
          && !(_id in path("drafts.**")) 
          && defined(publishedAt) 
          && dateTime(publishedAt + 'T00:00:00Z') < dateTime(now())
          && (count(hero[language == "${language}"]) > 0 || count(modules[language == "${language}"]) > 0)
          && select(
            defined(^.feed.filterTags) && count(^.feed.filterTags) > 0 => count(tags[_ref in ^.^.feed.filterTags[]._ref]) > 0,
            true
          )
        ] {
          "type": 'card.composable',
          _id,
          publishedAt,
          _updatedAt,
          "_key": _id,
          "themeName": "${COMPOSABLE_CARD_THEME_OPTIONS.pressrelease}",
          "title": source,
          "subtitle": title.${language},
          "badge": publishedAt,
          "text": coalesce(pt::text(hero[language == "${language}"][0].intro), description.${language}),
          "cover": ${imageQuery},
          "tags": tags[]-> {
            "title": title.${language},
            "slug": slug.${language}.current
          },
          "countries": countries[]-> {
            "title": title.${language},
            "slug": slug.${language}.current
          },
          "buttons": [{ 
            "label": ${getTranslationQuery('read_article', language)},
            "variant": 'secondary',
            "href": ${resolveIdHrefQuery}
          }]
        } | order(publishedAt desc, _updatedAt desc) [0...3],


        // free form cards
        items[] {
          "type": _type,
          theme,
          _key,
          _type == "card.composable" => {
            "cover": ${getImageQuery('cover')},
            "image": ${imageQuery},
            icon,
            badge,
            title,
            subtitle,
            text[] ${richTextQuery},
            buttons[] ${buttonQuery},
          },
          _type == "card.image" => {
            "image": ${imageQuery},
          },
        }
      )
    },
  
    // Billboard
    _type == "module.billboard" => {
      eyebrow,
      title,
      content[] ${richTextQuery},
      "image": ${imageQuery},
      buttons[] ${buttonQuery}
    },
  
    // Job details
    _type == "module.jobdetails" => {
      title
    },
  
    // Workable Application Form
    _type == "module.workableapplicationform" => {
      shortcode,
      form ->
    },
  
    // Job finder
    _type == "module.jobfinder" => {
      "jobs": ${jobsQuery},
      "countries": *[_type == 'page.location'] { 
        "name": title.${language},
        "code": select(
          defined(workableLocation) => workableLocation -> code, // countries with workable location connected
          _id == ^.otherCountriesLocation._ref => "${OTHER_COUNTRY_CODE}", // international = rest
          upper(slug.${language}.current) // fall back for countries with no workable location
        )
      },
      "departments": *[_type == 'page.department'] { 
        "name": title.${language},
        "code": workableDepartment -> name,
      },
    },
  
    // Job list
    _type == "module.joblist" => {
      eyebrow,
      title,
      buttons[] ${buttonQuery},
      filters {
        "countries": countries[] -> { code }.code,
        "departments": departments[] -> { name }.name,
        remote,
        noFilterBehaviour
      },
      "allCountries": *[_type == 'workable.location'] { code }.code,
      "allDepartments": *[_type == 'workable.department'] { name }.name,
    } {
      eyebrow,
      title,
      buttons,
      filters,
      allCountries,
      allDepartments,
      "jobs": select(

        // show no jobs
        ^.filters.noFilterBehaviour == 'show-no-jobs' => [],

        // show filtered jobs or latest 4
        *[_type == 'workable.job' && state == 'published'] {...} 
          [department in select(
            count(^.filters.departments) > 0 => ^.filters.departments, 
            ^.allDepartments)
          ] { ... } 
          [location.country_code in select(
            count(^.filters.countries) > 0 => ^.filters.countries, 
            ^.allCountries)
          ] { ... } 
          [select(
            ^.filters.remote == 'show-only-remote' => location.telecommuting == true,
            true
          )] | order(created_at desc) { ... }
      ) {
        ${jobQueryFields}
      } [0...4],
    },
  
    // Text Image
    _type == "module.textimage" => {
      title,
      eyebrow,
      showLozenges,
      intro[] ${richTextQuery},
      "image": ${imageQuery},
      buttons[] ${buttonQuery}
    },
    
    // Locations
    _type == "module.locations" => {
      title,
      eyebrow,
      intro[] ${richTextQuery},
      buttons[] ${buttonQuery},
      "items": *[_type == 'page.location' && _id != ^.^._id && !(_id in path("drafts.**"))] { 
        _id,
        "_key": _id,
        "country": title.${language},
        "offices": *[_type == 'page.office' && parent._ref == ^._id] { "title": title.${language} }.title,
        "href": ${resolveIdHrefQuery}
      }
    },
    
    // Gallery
    _type == "module.gallery" => {
      title,
      eyebrow,
      intro[] ${richTextQuery},
      items[] { 
        _key,
        "image": ${imageQuery}
      }
    },
    
    // Slides
    _type == "module.slides" => {
      title,
      eyebrow,
      intro[] ${richTextQuery},
      buttons[] ${buttonQuery},
      items[] { 
        _key,
        title,
        label,
        text,
        "image": ${imageQuery}
      }
    },

    // Story
    _type == "module.story" => {
      label,
      quote,
      text,
      person-> {
        name,
        "position": position.${language},
      },
      "image": ${imageQuery},
      "backgroundImage": ${getImageQuery('backgroundImage')},
      "videoLink": videoLink ${buttonQuery}.href,
    },
    
    // Article
    _type == "module.article" => {
      title,
      eyebrow,
      content[] ${richTextQuery}
    },
      
    // Article feed
    _type == "module.articlefeed" => {
      "feedType": feed.type,
      "items": *[
        _type match ('page.' + ^.feed.type)
        && !(_id in path("drafts.**")) 
        && defined(publishedAt) 
        && dateTime(publishedAt + 'T00:00:00Z') < dateTime(now())
        && (count(hero[language == "${language}"]) > 0 || count(modules[language == "${language}"]) > 0)
      ] {
        _id,
        publishedAt,
        _updatedAt,
        "_key": _id,
        "title": source,
        "sourceImage": ${getImageQuery('sourceImage')},
        "subtitle": title.${language},
        "date": select(
          defined(startDate) || defined(endDate) => [startDate, endDate],
          defined(publishedAt) => publishedAt,
          _updatedAt
        ),
        "text": pt::text(hero[language == "${language}"][0].intro),
        "cover": ${imageQuery},
        "PressReleaseLogoField": {
          "src": logo->image.asset->url,
          "width": logo->image.asset->metadata.dimensions.width,
          "height": logo->image.asset->metadata.dimensions.height
        },      
        "tags": tags[]-> {
          "title": title.${language},
          "slug": slug.${language}.current
        },
        "countries": countries[]-> {
          "title": title.${language},
          "slug": slug.${language}.current
        },
        "buttons": [{ 
          "label": ${getTranslationQuery('read_article', language)},
          "variant": 'secondary',
          "alt": true,
          "href": ${resolveIdHrefQuery}
        }]
      } | order(publishedAt desc, _updatedAt desc)
    },
  },

  // dialogs
  "dialogs": dialogs[language == "${language}"] {
    _key,
    _type,
    "slug": slug.current,
    _type == "dialog.richtext" => {
      content[] ${richTextQuery}
    },

    _type == "dialog.video" => {
      ${videoQuery}
    },

    _type == "dialog.form" => {
      form${staticFormQuery}    
    },
  },
}`;
