import cx from 'classnames';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';

import { Breadcrumb } from '../../components/Breadcrumb/Breadcrumb';
import { Link } from '../../components/buttons/Link';
import { IconLoader } from '../../components/images/IconLoader';
import { Wrapper } from '../../components/module/Wrapper';
import { PageContext } from '../../context/PageContext';
import { getItemForId } from '../../helpers/sitemap/getItemForId';
import { getItemForPath } from '../../helpers/sitemap/getItemForPath';
import { useWindowSize, WindowSize } from '../../hooks/useWindowSize';
import { IconType } from '../../types';
import { Logo } from '../Nav/Logo';

export type FooterProps = {
  socials: { label?: string; href?: string; icon: IconType }[];
  links: {
    title?: string;
    href?: string;
    current?: boolean;
    items: { label?: string; href?: string; current?: boolean }[];
  }[];
  copyright?: string;
  legal?: string;
  legalLinks?: { label?: string; href?: string }[];
};

export const Footer = ({
  socials,
  links,
  copyright = '©',
  legal,
  legalLinks,
}: FooterProps) => {
  const { sitemap, locale, sitemapItem } = useContext(PageContext);
  const { width }: WindowSize = useWindowSize();
  const MAX_MOBILE_SIZE = 768;
  const router = useRouter();

  // find the link to the jobs page to show separately on mobile
  const pages: { label?: string; href?: string }[] = links?.reduce(
    (prev, curr) => [...(curr?.items || []), ...prev],
    [] as FooterProps['links'],
  );

  const jobsPageHref = getItemForId('page_jobs', sitemap)?.paths[locale];
  const jobsPageButton = pages?.find(({ href }) => href === jobsPageHref);

  // set active state
  links = links?.map((item) => ({
    ...item,
    current:
      router.asPath === '/'
        ? item.href === '/'
        : router.asPath.startsWith(item.href) && item.href !== '/',
    items: item.items?.map((subitem) => ({
      ...subitem,
      current: router.asPath === subitem.href,
    })),
  }));

  return (
    <footer>
      <Wrapper theme={{ space: { top: 'none', bottom: 'sm' } }}>
        {sitemapItem && sitemapItem._id !== 'page_homepage' && (
          <div className="pb-4 mb-4 border-b border-b-neutral-85 text-base text-neutral-base">
            <Breadcrumb />
          </div>
        )}
        <div className="flex flex-col xl:flex-row gap-8">
          <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 sm:gap-x-8 md:gap-x-8 md:gap-y-10 flex-grow">
            {links?.map(({ title, href, current, items }) => (
              <details
                key={title || JSON.stringify(items)}
                open={!width || width > MAX_MOBILE_SIZE ? true : current}
                className="group relative w-full"
              >
                <span className="pointer-events-none absolute -inset-x-4 -top-3 border border-white bottom-0 hidden group-open:block md:group-open:hidden group-open:bg-neutral-95 rounded-lg -z-10" />
                <summary
                  className="text-neutral-base font-bold text-xl mb-4 list-none relative"
                  onClick={(e) => {
                    if (width && width > MAX_MOBILE_SIZE) e.preventDefault();
                  }}
                >
                  {href ? (
                    <Link href={href} className="hover:underline underline-offset-4">
                      {title}
                    </Link>
                  ) : (
                    title
                  )}

                  <IconLoader
                    icon="chevron"
                    className="absolute right-0 top-1/2 -translate-y-1/2 w-4 h-4  md:hidden text-action-base transition-transform duration-75 group-open:rotate-180"
                  />
                </summary>

                <ul className="flex flex-col gap-4 pb-6 md:pb-0">
                  {items?.map(({ label, href, current }) => {
                    const isJobsPage =
                      getItemForPath(href, sitemap, locale)?._id == 'page_jobs';

                    return (
                      <li
                        key={href || label}
                        className={cx('text-neutral-25 text-md font-bold relative', {
                          ['mt-2 hidden md:block']: isJobsPage,
                        })}
                      >
                        {href ? (
                          <Link
                            href={href}
                            className={cx('hover:underline underline-offset-4', {
                              ['text-action-base']: current && !isJobsPage,
                              ['pyxis-btn pyxis-btn-huge pyxis-btn-primary']:
                                isJobsPage,
                            })}
                          >
                            {label}
                          </Link>
                        ) : (
                          label
                        )}

                        {current && (
                          <span className="md:hidden bg-action-base w-0.5 h-5 absolute -left-2 top-0" />
                        )}
                      </li>
                    );
                  })}
                </ul>
              </details>
            ))}

            {/* mobile jobs link */}
            {jobsPageButton && (
              <div className="md:hidden">
                <Link
                  href={jobsPageButton.href}
                  className="hover:underline underline-offset-4 pyxis-btn pyxis-btn-huge pyxis-btn-primary"
                >
                  {jobsPageButton.label}
                </Link>
              </div>
            )}
          </div>
        </div>

        <div className="mt-10 md:mt-[100px] text-md text-neutral-base">
          <div className="flex gap-10 flex-wrap">
            <div className="flex gap-3 md:gap-10 flex-col md:flex-row flex-wrap">
              <div className="translate-y-1 block">
                <Link href="/">
                  <Logo />
                </Link>
              </div>

              {(legal || copyright) && (
                <p className="text-neutral-25 text-sm leading-relaxed">
                  {copyright && <strong className="block">{copyright}</strong>}
                  {legal && <span className="block">{legal}</span>}
                </p>
              )}
            </div>

            <div className="flex-1">
              {(Boolean(socials?.length) || Boolean(legalLinks?.length)) && (
                <ul className="flex md:justify-end gap-4 md:gap-6 items-center">
                  {legalLinks?.map(({ label, href }) => (
                    <li
                      key={label}
                      className="border-r border-neutral-85 pr-4 md:pr-6"
                    >
                      <Link href={href} className="font-bold text-neutral-25">
                        {label}
                      </Link>
                    </li>
                  ))}

                  {socials?.map(({ label, href, icon }) => (
                    <li key={label}>
                      <Link
                        href={href}
                        className="block w-7 h-7 overflow-hidden text-neutral-base"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <IconLoader icon={icon} />
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </Wrapper>
    </footer>
  );
};
