import { LanguageType } from '../../languages';
import { SitemapItemType, SitemapType } from '../../queries/sitemap';
import { getItemForPath } from './getItemForPath';

export const getBreadCrumbForPath = (
  path: string,
  sitemap: SitemapType,
  locale: LanguageType,
): SitemapItemType[] => {
  const pathParts = path?.split('#')[0].split('/');

  return path
    ?.split('/')
    .slice(1)
    .map((x, i) =>
      getItemForPath(`/${pathParts.slice(1, i + 2).join('/')}`, sitemap, locale),
    )
    .filter(Boolean)
    .map((item) => ({
      ...item,
      path: item.paths[locale],
      title: item.titles[locale],
    }));
};
