import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import { isInternalLink } from '../../helpers/sitemap/isInternalLink';
import { LanguageType } from '../../languages';
import { IconLoader } from '../images/IconLoader';

export type LinkProps = {
  href: string;
  children?: React.ReactElement | React.ReactNode;
  className?: string;
  target?: '_blank';
  rel?: string;
  locale?: LanguageType;
  download?: boolean;
};

export const Link = ({
  href,
  children,
  className,
  target,
  rel,
  locale,
  download,
}: LinkProps) => {
  const router = useRouter();

  if (!locale) locale = router?.locale as LanguageType;

  if (isInternalLink(href)) {
    return (
      <NextLink
        href={href}
        shallow={href?.indexOf('#') === 0}
        locale={locale}
        target={target}
        download={download}
      >
        <a className={className} target={target} rel={rel}>
          {children}
          {target === '_blank' && (
            <IconLoader icon="external-link" className="ml-1 inline-block w-4 h-4" />
          )}
        </a>
      </NextLink>
    );
  }

  return (
    <a
      href={href}
      className={className}
      target={target}
      rel={rel}
      download={download}
    >
      {children}
      {target === '_blank' && (
        <IconLoader icon="external-link" className="ml-1 inline-block w-4 h-4" />
      )}
    </a>
  );
};

export const LinkMemo = React.memo(Link);
