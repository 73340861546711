export const slugify = (str = '') => {
  return (str || '')
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const truncate = (str, maxLength) =>
  str.length <= maxLength ? str : `${str.slice(0, maxLength)}…`;

/**
 * Process string
 * https://github.com/EfogDev/react-process-string/blob/master/index.js
 * https://github.com/EfogDev/react-process-string#readme
 */

export const processString = (options) => {
  let key = 0;

  function processInputWithRegex(option, input) {
    if (!option.fn || typeof option.fn !== 'function') return input;

    if (!option.regex || !(option.regex instanceof RegExp)) return input;

    if (typeof input === 'string') {
      let regex = option.regex;
      let result = null;
      let output = [];

      while ((result = regex.exec(input)) !== null) {
        let index = result.index;
        let match = result[0];

        output.push(input.substring(0, index));
        output.push(option.fn(++key, result));

        input = input.substring(index + match.length, input.length + 1);
        regex.lastIndex = 0;
      }

      output.push(input);
      return output;
    } else if (Array.isArray(input)) {
      return input.map((chunk) => processInputWithRegex(option, chunk));
    } else return input;
  }

  return function (input) {
    if (!options || !Array.isArray(options) || !options.length) return input;

    options.forEach((option) => (input = processInputWithRegex(option, input)));

    return input;
  };
};

/**
 * console.log(list([ {name: 'Bart'}, {name: 'Lisa'}, {name: 'Maggie'} ])) // returns 'Bart, Lisa & Maggie'
 * console.log(list([ {name: 'Bart'}, {name: 'Lisa'} ])) // returns 'Bart & Lisa'
 * console.log(list([ {name: 'Bart'} ])) // returns 'Bart'
 */

export function joinList(items: string[]) {
  items = JSON.parse(JSON.stringify(items));
  const finalString = items.pop();
  return items.length ? items.join(', ') + ' & ' + finalString : finalString;
}
