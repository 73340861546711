import groq from 'groq';

import { buttonHrefQuery, buttonQuery } from './button';
import { imageSourceQuery } from './image';
import { videoSourceQuery } from './video';

export const richTextImage = `
_type == "image.simple" => {
  ...,
  "image": ${imageSourceQuery},
}`;

export const richTextVideo = `
_type == "video" => {
  "video": ${videoSourceQuery}
}`;

export const richTextButtons = `
_type == "buttons" => {
  "direction": direction,
  "items": items[] ${buttonQuery}
}`;

export const richTextCSV = `
_type == "csv" => {
  "file": file.asset->url,
  "fileName": file.asset->originalFilename,
}`;

export const richTextDownloads = `
_type == "downloads" => {
  items[] -> {
    title,
    language,
    link,
    "file": file.asset->url + '?dl=' + file.asset->originalFilename,
    "filename": file.asset->originalFilename,
    "filesize": file.asset->size
  }
}`;

export const richTextMarkDefs = `
markDefs[]{
  ...,
  _type == "link" => {
    "href": ${buttonHrefQuery},
    "target": select(newWindow => '_blank')
  }
}`;

export const richTexAccordion = `
_type == "accordion" => {
  items[] {
    title,
    subtitle,
    icon,
    content[]
  }
}`;

export const richTextButtonCardGroup = `
_type == "buttonCardGroup" => {
  items[] {
    title,
    subtitle,
    icon,
    link {
      _key,
      language,
      "href": ${buttonHrefQuery},
      download,
      "target": select(newWindow => '_blank') 
    }
  }
}`;

export const richTextQueryFields = groq`
  ...,
  ${richTextImage},
  ${richTextVideo},
  ${richTextButtons},
  ${richTextCSV},
  ${richTextDownloads},
  ${richTextMarkDefs},
  ${richTextButtonCardGroup}
 `;

export const richTextQuery = groq`
{
  ${richTextQueryFields}
}
`;
