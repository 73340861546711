export const Logo = () => (
  <svg
  className="block h-[34px] w-[94px] md:h-[36px] md:w-[118px]"
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  shapeRendering="geometricPrecision"
  viewBox="0 0 118 36"
  aria-label="Logo"
>
  <path
    d="M64.8156 4.87294C63.9146 5.18746 63.5455 5.70805 63.5564 6.695C63.6215 11.7274 63.5889 16.7598 63.5889 21.7813C63.5889 22.1717 63.5998 22.5513 63.5781 22.9418C63.513 24.2541 62.7965 25.0567 61.711 25.0675C60.5494 25.0784 59.7027 24.2324 59.6919 22.9092C59.6701 16.8465 59.6593 10.7838 59.7244 4.7211C59.7353 4.12459 60.1478 3.31116 60.6471 2.99664C64.8265 0.328614 69.3315 -0.25705 74.0536 1.51079C74.9437 1.847 75.6819 2.56282 76.6046 3.18102C78.7757 1.42402 81.2724 0.523836 84.1708 0.534681C89.8916 0.556373 93.0288 3.14848 93.2785 8.59299C93.5064 13.3976 93.3653 18.2239 93.3436 23.0394C93.3328 24.3083 92.486 25.0892 91.3679 25.0675C90.3475 25.0458 89.5008 24.2541 89.4791 23.0828C89.4248 19.5579 89.4465 16.0331 89.4357 12.5083C89.4357 11.4779 89.4682 10.4476 89.4031 9.41726C89.1317 5.12239 86.3093 3.18102 81.9237 4.30896C81.1096 4.51503 80.3497 4.94886 79.6224 5.37183C78.7757 5.87073 78.4066 6.58655 78.4174 7.62773C78.4826 12.7469 78.4609 17.8552 78.4391 22.9743C78.4391 24.2541 77.6467 25.0675 76.5612 25.0675C75.4322 25.0675 74.6615 24.2649 74.5963 23.0285C74.5746 22.6815 74.5855 22.3453 74.5855 21.9982C74.5855 18.0829 74.5855 14.1785 74.5855 10.2632C74.5855 9.78601 74.5963 9.31965 74.5529 8.84244C74.2272 5.64298 72.2841 3.95106 68.8864 4.07036C68.8972 4.07036 67.0844 4.0812 64.8156 4.87294Z"
    fill="#8334C2"
  />
  <path
    d="M33.6496 6.04459C33.9753 2.87766 36.2332 0.914604 39.555 0.621772C41.3787 0.459087 43.2567 0.665154 45.0804 0.892913C46.4699 1.06644 47.1429 1.98832 46.9367 2.98612C46.7304 3.98392 45.7534 4.50451 44.3965 4.37436C43.1807 4.26591 41.9649 4.07068 40.7599 4.12491C38.6214 4.22252 37.5142 5.2637 37.4382 7.29184C37.3405 10.2527 37.373 13.2136 37.3622 16.1853C37.3513 18.2893 37.373 20.4693 37.3513 22.5733C37.3405 24.1676 36.6566 25.0461 35.4516 25.0787C34.1598 25.1221 33.3891 24.211 33.3891 22.595C33.3782 20.1005 33.3891 17.541 33.3891 15.0465C33.4651 15.0465 33.3457 9.01629 33.6496 6.04459Z"
    fill="#8334C2"
  />
  <path
    d="M54.4161 12.931C54.4161 16.1955 54.427 19.46 54.4161 22.7246C54.4053 24.2213 53.7105 25.0672 52.5381 25.0782C51.2898 25.0889 50.5516 24.1996 50.5516 22.6703C50.5407 16.228 50.5407 15.6424 50.5516 9.21091C50.5516 7.59491 51.2029 6.75979 52.4513 6.74895C53.7648 6.72726 54.4161 7.56237 54.427 9.26513C54.4161 12.4321 54.4161 9.75319 54.4161 12.931Z"
    fill="#8334C2"
  />
  <path
    d="M52.4078 4.72098C51.3114 4.71013 50.3127 3.72318 50.3019 2.64946C50.291 1.45644 51.2246 0.54541 52.4512 0.54541C53.6779 0.54541 54.6657 1.49983 54.6223 2.62777C54.6006 3.77741 53.5802 4.73183 52.4078 4.72098Z"
    fill="#8334C2"
  />
  <path
    d="M28.4933 7.81246C27.8746 3.94057 25.1607 1.51115 21.0248 0.784492C19.0057 0.437432 17.0626 0.49166 15.1846 0.849566C14.3379 0.990559 13.73 1.66299 13.7083 2.53064C13.6865 3.47421 14.5658 4.34186 15.5211 4.32017C15.6622 4.32017 15.7925 4.29848 15.9228 4.26594C17.2146 3.97311 18.5389 3.88634 19.9284 4.13579C23.0331 4.66723 24.5962 6.32661 24.9219 9.97075C25.1281 12.3026 25.0304 14.6994 24.7482 17.0313C24.4117 19.9162 22.4686 21.4997 19.4182 21.76C17.0734 21.9552 14.8372 21.4997 12.6987 20.5778C12.0365 20.2958 11.7651 19.9379 11.7869 19.2221C11.8411 17.118 11.8086 15.014 11.8086 12.9099C11.8086 11.2071 11.7869 9.49354 11.8194 7.79077V4.81907C11.8194 3.81042 10.9401 2.96446 9.86545 2.94277C9.53978 2.93193 9.23583 2.997 8.96445 3.10546C6.72823 4.08157 5.12163 4.91668 1.4308 6.85805C0.0413039 7.61724 -0.338635 8.7235 0.301834 9.78637C0.898881 10.795 1.98442 10.9685 3.29793 10.2636C4.80683 9.45015 6.34829 8.66927 7.91147 7.92092C7.94404 15.9033 7.92233 26.0765 7.93318 33.7878V33.7986C7.93318 33.8095 7.93318 33.8095 7.93318 33.8095C7.93318 33.8311 7.93318 33.842 7.93318 33.8637H7.94404C7.98746 34.8506 8.83418 35.6424 9.8763 35.6424C10.9184 35.6424 11.776 34.8506 11.8086 33.8637H11.8194C11.8303 31.6403 11.8194 27.7793 11.8194 24.1568C13.9905 24.5581 15.9553 25.0787 17.9527 25.2522C23.489 25.7295 27.8963 22.7361 28.6562 17.541C29.1121 14.3524 29.0035 11.0011 28.4933 7.81246Z"
    fill="#8334C2"
  />
  <path
    d="M117.692 7.83384C117.584 4.35239 115.499 1.90128 112.102 1.03363C108.248 0.0466745 104.47 0.523883 100.812 1.94466C99.607 2.41102 99.162 3.38713 99.531 4.31985C99.8784 5.19835 100.91 5.52372 102.201 5.14412C102.201 5.14412 105.599 4.02702 108.954 4.04871C111.809 4.07041 113.426 5.4478 113.817 7.96399C113.882 8.40866 113.893 8.86418 113.882 9.34138H113.893L113.925 14.2545V14.3087C113.925 14.3304 113.925 14.3629 113.925 14.3846V14.5039C113.925 16.2717 113.98 17.8877 113.882 19.4929C113.86 19.9376 113.48 20.5775 113.079 20.7727C110.256 22.1501 107.271 22.367 104.264 21.4451C102.343 20.8486 101.409 19.417 101.409 17.4973C101.409 15.7512 102.451 14.4063 104.373 13.7664C106.251 13.1482 108.085 13.1807 109.92 13.4627C110.007 13.4736 110.104 13.4844 110.202 13.4844C111.07 13.4844 111.765 12.7795 111.765 11.9226C111.765 11.1635 111.222 10.5344 110.495 10.3934C108.074 10.0355 105.653 9.97043 103.211 10.6537C99.607 11.6623 97.5336 14.146 97.5227 17.4756C97.5119 21.1089 99.5093 23.8528 103.178 24.753C107.901 25.9244 112.438 25.1652 116.639 22.855C117.16 22.5731 117.681 21.7922 117.692 21.239C117.779 16.7815 117.844 12.3022 117.692 7.83384Z"
    fill="#8334C2"
  />
</svg>
);