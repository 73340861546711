import { useRouter } from 'next/dist/client/router';
import React, { useEffect, useRef } from 'react';

import { PageLock } from '../../components/PageLock/PageLock';
import { IconLoader } from '../../components/images/IconLoader';
import { Spinner } from '../../components/loaders/Spinner';
import { Seo } from '../../components/meta/Seo';
import { PageContext } from '../../context/PageContext';
import { LanguageType } from '../../languages';
import { ConfigType } from '../../queries/config';
import { FooterType } from '../../queries/footer';
import { NavigationType } from '../../queries/navigation';
import { PageType } from '../../queries/page';
import { SitemapItemType, SitemapType } from '../../queries/sitemap';
import { Footer } from '../Footer/Footer';
import { Nav } from '../Nav/Nav';
import { DefaultPage } from './DefaultPage';

export type PageProps = {
  preview: boolean;
  previewLoading?: boolean;
  reloadPreview?: () => void;
  navigation: NavigationType;
  page: PageType;
  footer: FooterType;
  config: ConfigType;
  sitemap: SitemapType;
  sitemapItem?: SitemapItemType;
  locked?: boolean;
};

export const Page = ({
  preview,
  previewLoading = false,
  reloadPreview = () => {},
  navigation,
  page,
  footer,
  config,
  sitemap,
  sitemapItem,
  locked,
}: PageProps) => {
  const router = useRouter();
  const pagePath = router.asPath;
  const previewTools = useRef(null);

  // set active state
  const navItems = navigation?.items?.map((item) => ({
    ...item,
    current:
      router.asPath === '/'
        ? item.href === '/'
        : router.asPath.startsWith(item.href) && item.href !== '/',
    children: item.children?.map((subitem) => ({
      ...subitem,
      current: router.asPath === subitem.href,
    })),
  }));

  /**
   * Handle preset screenshots
   */

  useEffect(() => {
    async function onMessage(event) {
      if (event.data.type !== 'captureScreenshot') return;

      const html2canvas = (await import('html2canvas')).default;
      previewTools.current.style.visibility = 'hidden';

      html2canvas(document.body, {
        width: event.data.width,
        height: event.data.height,
        windowWidth: event.data.width,
        windowHeight: event.data.height,
        useCORS: true,
      }).then((canvas) => {
        previewTools.current.style.visibility = 'visible';

        canvas.toBlob((blob) => {
          event.source.postMessage(
            { type: 'captureScreenshot', image: blob },
            event.origin,
          );
        });
      });
    }

    window.addEventListener('message', onMessage, false);
    return () => window.removeEventListener('message', onMessage);
  }, []);

  return (
    <PageContext.Provider
      value={{
        preview,
        sitemap,
        config,
        sitemapItem,
        locale: router.locale as LanguageType,
      }}
    >
      <Seo page={page} config={config} sitemap={sitemap} preview={preview} />

      {preview && (
        <div
          className="text-md fixed top-4 right-4 z-50 flex gap-1 text-white"
          ref={previewTools}
        >
          <button
            className="block px-3 bg-[#111] transition-color hover:underline hover:bg-[#222]"
            onClick={reloadPreview}
          >
            <svg
              className="w-5 h-5 block"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.84998 7.49998C1.84998 4.66458 4.05979 1.84998 7.49998 1.84998C10.2783 1.84998 11.6515 3.9064 12.2367 5H10.5C10.2239 5 10 5.22386 10 5.5C10 5.77614 10.2239 6 10.5 6H13.5C13.7761 6 14 5.77614 14 5.5V2.5C14 2.22386 13.7761 2 13.5 2C13.2239 2 13 2.22386 13 2.5V4.31318C12.2955 3.07126 10.6659 0.849976 7.49998 0.849976C3.43716 0.849976 0.849976 4.18537 0.849976 7.49998C0.849976 10.8146 3.43716 14.15 7.49998 14.15C9.44382 14.15 11.0622 13.3808 12.2145 12.2084C12.8315 11.5806 13.3133 10.839 13.6418 10.0407C13.7469 9.78536 13.6251 9.49315 13.3698 9.38806C13.1144 9.28296 12.8222 9.40478 12.7171 9.66014C12.4363 10.3425 12.0251 10.9745 11.5013 11.5074C10.5295 12.4963 9.16504 13.15 7.49998 13.15C4.05979 13.15 1.84998 10.3354 1.84998 7.49998Z"
                fill="currentColor"
              ></path>
            </svg>
          </button>
          <span className="flex gap-4 bg-[#111] items-center">
            <span className="pl-3">preview mode</span>

            <a
              className="p-3 bg-[#111] transition-color hover:underline hover:bg-[#222] border-l border-l-neutral-25"
              href={`/api/preview/exit-preview?redirect=${pagePath}`}
            >
              <span className="w-5 h-5 block">
                {previewLoading ? <Spinner /> : <IconLoader icon="close" />}
              </span>
            </a>
          </span>
        </div>
      )}

      {page && navigation && !preview && (
        <Nav
          items={page.hideNav === true ? [] : navItems}
          buttons={page.hideNav === true ? [] : navigation.buttons}
          showSearch={page.hideNav !== true}
        />
      )}

      <DefaultPage {...page} />

      {locked && !preview && <PageLock />}

      {page && footer && !preview && (
        <Footer
          links={page.hideFooter === true ? [] : footer.links}
          socials={page.hideFooter === true ? [] : footer.socials}
          copyright={footer.copyright}
          legal={footer.legal}
          legalLinks={footer.legalLinks}
        />
      )}
    </PageContext.Provider>
  );
};
