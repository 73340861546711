import dynamic from 'next/dynamic';
import React, { useContext } from 'react';
import { Suspense } from 'react';

import { type PortableTextProps } from '../../components/content/PortableText';
import { PageContext } from '../../context/PageContext';
import { type ArticleProps } from '../../modules/Article/Article';
import { type ArticleFeedProps } from '../../modules/ArticleFeed/ArticleFeed';
import { type BillboardProps } from '../../modules/Billboard/Billboard';
import { type BreadcrumbProps } from '../../modules/Breadcrumb/Breadcrumb';
import { type CardGridProps } from '../../modules/CardGrid/CardGrid';
import { type GalleryProps } from '../../modules/Gallery/Gallery';
import { type JobDetailsProps } from '../../modules/JobDetails/JobDetails';
import { type JobFinderProps } from '../../modules/JobFinder/JobFinder';
import { type JobListProps } from '../../modules/JobList/JobList';
import { type LocationsProps } from '../../modules/Locations/Locations';
import { type RichTextProps } from '../../modules/RichText/RichText';
import { type SlidesProps } from '../../modules/Slides/Slides';
import { type StoryProps } from '../../modules/Story/Story';
import { type TextImageProps } from '../../modules/TextImage/TextImage';
import { type WorkableApplicationFormProps } from '../../modules/WorkableApplicationForm/WorkableApplicationForm';
import { type ModuleSchemaName } from '../../types.sanity';
import { LazyLoadInView } from './LazyLoadInView';
import ModuleErrorBoundary from './ModuleErrorBoundary';
import { StaticFormBuilderMemo as StaticFormBuilder } from './StaticFormBuilder';

const ArticleFeed = dynamic<ArticleFeedProps>(
  () =>
    import(
      /* webpackChunkName: "ArticleFeed" */ '../../modules/ArticleFeed/ArticleFeed'
    ) as any,
  { suspense: true },
);

const Article = dynamic<ArticleProps>(
  () =>
    import(/* webpackChunkName: "Article" */ '../../modules/Article/Article') as any,
  { suspense: true },
);

const Story = dynamic<StoryProps>(
  () => import(/* webpackChunkName: "Story" */ '../../modules/Story/Story') as any,
  { suspense: true },
);

const Slides = dynamic<SlidesProps>(
  () =>
    import(/* webpackChunkName: "Slides" */ '../../modules/Slides/Slides') as any,
  { suspense: true },
);

const Gallery = dynamic<GalleryProps>(
  () =>
    import(/* webpackChunkName: "Gallery" */ '../../modules/Gallery/Gallery') as any,
  { suspense: true },
);

const Locations = dynamic<LocationsProps>(
  () =>
    import(
      /* webpackChunkName: "Locations" */ '../../modules/Locations/Locations'
    ) as any,
  { suspense: true },
);

const TextImage = dynamic<TextImageProps>(
  () =>
    import(
      /* webpackChunkName: "TextImage" */ '../../modules/TextImage/TextImage'
    ) as any,
  { suspense: true },
);

const JobList = dynamic<JobListProps>(
  () =>
    import(/* webpackChunkName: "JobList" */ '../../modules/JobList/JobList') as any,
  { suspense: true },
);

const JobFinder = dynamic<JobFinderProps>(
  () =>
    import(
      /* webpackChunkName: "JobFinder" */ '../../modules/JobFinder/JobFinder'
    ) as any,
  { suspense: true },
);

const WorkableApplicationForm = dynamic<WorkableApplicationFormProps>(
  () =>
    import(
      /* webpackChunkName: "WorkableApplicationForm" */ '../../modules/WorkableApplicationForm/WorkableApplicationForm'
    ) as any,
  { suspense: true },
);

const JobDetails = dynamic<JobDetailsProps>(
  () =>
    import(
      /* webpackChunkName: "JobDetails" */ '../../modules/JobDetails/JobDetails'
    ) as any,
  { suspense: true },
);

const Billboard = dynamic<BillboardProps>(
  () =>
    import(
      /* webpackChunkName: "Billboard" */ '../../modules/Billboard/Billboard'
    ) as any,
  { suspense: true },
);

const PortableText = dynamic<PortableTextProps>(
  () =>
    import(
      /* webpackChunkName: "PortableText" */ '../../components/content/PortableText'
    ) as any,
  { suspense: true },
);

const Breadcrumb = dynamic<BreadcrumbProps>(
  () =>
    import(
      /* webpackChunkName: "Breadcrumb" */ '../../modules/Breadcrumb/Breadcrumb'
    ) as any,
  { suspense: true },
);

const CardGrid = dynamic<CardGridProps>(
  () =>
    import(
      /* webpackChunkName: "CardGrid" */ '../../modules/CardGrid/CardGrid'
    ) as any,
  { suspense: true },
);

const RichText = dynamic<RichTextProps>(
  () =>
    import(
      /* webpackChunkName: "RichText" */ '../../modules/RichText/RichText'
    ) as any,
  { suspense: true },
);

// TODO: losing all typing here, there's no connection between queried modules and props for now
export type ModuleBuilderProps = {
  items: any & {
    _type: ModuleSchemaName;
    _key: string;
    [key: string]: any;
  };
};

// Sections that need to be loaded before network idle or inview
// It won't load if you don't add it here when for instance a module is position: fixed.
const NON_LAZY_LOAD_SECTIONS: ModuleSchemaName[] = [];
const INITIAL_SECTIONS_TO_LOAD: number = 2;
const INVIEW_LOAD_ONLY_SECTIONS: ModuleSchemaName[] = [];

export const ModuleBuilder = ({ items }: ModuleBuilderProps) => {
  const { sitemapItem } = useContext(PageContext);

  const hasH1 = items.some(({ _type }) => _type.startsWith('hero.'));

  return (
    <main>
      {/* insert h1 at the top if there are no hero and no breadcrumbs */}
      {!hasH1 && sitemapItem?.title && (
        <h1 className="sr-only">{sitemapItem.title}</h1>
      )}

      {items?.map((item, i) => (
        <Suspense fallback={``} key={item._key}>
          <ModuleErrorBoundary>
            <LazyLoadInView
              // show essential sections immediately
              enabled={
                i > INITIAL_SECTIONS_TO_LOAD &&
                NON_LAZY_LOAD_SECTIONS.indexOf(item._type) === -1
              }
              // load non essential sections after network idle
              // and heavy non essential sections only when in view
              networkIdle={INVIEW_LOAD_ONLY_SECTIONS.indexOf(item._type) === -1}
              background={item.theme?.background}
              module={item._type}
            >
              {/* rich text */}
              {item._type === 'module.richtext' && (
                <RichText
                  {...item}
                  content={<PortableText content={item.content} />}
                />
              )}

              {/* Breadcrumb */}
              {item._type === 'module.breadcrumb' && <Breadcrumb {...item} />}

              {/* Card Grid */}
              {item._type === 'module.cardgrid' && (
                <CardGrid
                  {...item}
                  intro={item.intro ? <PortableText content={item.intro} /> : null}
                  items={item.items?.map((item) => ({
                    ...item,
                    text: item?.text ? (
                      typeof item?.text === 'string' ? (
                        item.text
                      ) : (
                        <PortableText content={item.text} />
                      )
                    ) : null,
                  }))}
                />
              )}

              {/* Billboard */}
              {item._type === 'module.billboard' && (
                <Billboard
                  {...item}
                  content={
                    item.content ? <PortableText content={item.content} /> : null
                  }
                />
              )}

              {/* Job details */}
              {item._type === 'module.jobdetails' && <JobDetails {...item} />}

              {/* Workable Application Form */}
              {item._type === 'module.workableapplicationform' && (
                <WorkableApplicationForm {...item}>
                  {item.form?.formId && <StaticFormBuilder {...item.form} />}
                </WorkableApplicationForm>
              )}

              {/* Job finder */}
              {item._type === 'module.jobfinder' && <JobFinder {...item} />}

              {/* Job list */}
              {item._type === 'module.joblist' && <JobList {...item} />}

              {/* Text Image */}
              {item._type === 'module.textimage' && (
                <TextImage {...item} intro={<PortableText content={item.intro} />} />
              )}

              {/* Locations */}
              {item._type === 'module.locations' && (
                <Locations {...item} intro={<PortableText content={item.intro} />} />
              )}

              {/* Gallery */}
              {item._type === 'module.gallery' && (
                <Gallery {...item} intro={<PortableText content={item.intro} />} />
              )}

              {/* Slides */}
              {item._type === 'module.slides' && (
                <Slides {...item} intro={<PortableText content={item.intro} />} />
              )}

              {/* Story */}
              {item._type === 'module.story' && <Story {...item} />}

              {/* Article */}
              {item._type === 'module.article' && (
                <Article
                  {...item}
                  content={<PortableText content={item.content} />}
                />
              )}

              {/* Article feed */}
              {item._type === 'module.articlefeed' && <ArticleFeed {...item} />}
            </LazyLoadInView>
          </ModuleErrorBoundary>
        </Suspense>
      ))}
    </main>
  );
};

export const ModuleBuilderMemo = React.memo(ModuleBuilder);
