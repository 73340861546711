import cx from 'classnames';
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { textClasses } from '../../colors';
import { IconType, ICONS, ColorType } from '../../types';

type IconLoaderProps = {
  icon?: IconType;
  className?: string;
  color?: ColorType;
  as?: React.ElementType;
  title?: string;
  description?: string;
  style?: React.CSSProperties;
  domain?: string;
  path?: string;
  removeColors?: boolean;
};

export const IconLoader = ({
  icon,
  className,
  color,
  as = 'span',
  title,
  description,
  style,
  domain = '/',
  path = 'icons/',
  removeColors = true,
}: IconLoaderProps) => {
  const Element = as;
  const [isMounted, setIsMounted] = useState(false);

  const {
    isLoading,
    error,
    data,
  }: {
    isLoading?: boolean;
    error?: Error;
    data?: string;
  } = useQuery(
    icon,
    () =>
      fetch(`${domain}${path}${ICONS[icon]}`).then(async (res) => {
        if (res.status !== 200) return '';

        const html = await res.text();
        if (!html.startsWith('<svg')) return '';

        let cleanHTML = DOMPurify.sanitize(html);
        cleanHTML = cleanUpAttributes(cleanHTML);
        if (removeColors) {
          cleanHTML = replaceColorsWithCurrentColor(cleanHTML);
        }
        return cleanHTML;
      }),
    { enabled: Boolean(icon) && Boolean(ICONS[icon]) },
  );

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!ICONS[icon]) return null;

  if (error) {
    console.log(`Error loading icon ${icon}: ${error?.message}`);
    return null;
  }

  if (!isMounted) return null;

  if (isLoading || error)
    return (
      <Element
        role="img"
        aria-hidden="true"
        aria-label={[title, description].filter(Boolean).join(', ')}
        className={className}
        style={style}
      />
    );

  return (
    <Element
      role="img"
      aria-hidden="true"
      aria-label={[title, description].filter(Boolean).join(', ')}
      className={cx(className, textClasses[color])}
      dangerouslySetInnerHTML={{ __html: data }}
      style={style}
    />
  );
};

/**
 * Remove width / height / style
 */

const cleanUpAttributes = (str) => {
  let parser = new DOMParser();
  let parsedResult = parser.parseFromString(str, 'image/svg+xml');

  // remove width and height so we can style with css
  parsedResult.documentElement.removeAttribute('width');
  parsedResult.documentElement.removeAttribute('height');

  // remove style attributes as they might overwrite styles on the site
  parsedResult.documentElement
    .querySelectorAll('style')
    .forEach((style) => style.parentNode.removeChild(style));

  return parsedResult.documentElement.outerHTML;
};

/**
 * Replace colours with currentColor so we can style them
 */

const replaceColorsWithCurrentColor = (str) => {
  return str.replace(/((stroke|fill)=")(\w+)/g, '$1currentColor');
};
