export type LanguageType = 'en' | 'it' | 'es';

export const languages: {
  id: LanguageType;
  title: string;
}[] = [
  { id: 'en', title: 'English' },
  { id: 'it', title: 'Italiano' },
  { id: 'es', title: 'Español' },
];

export const baseLanguage: LanguageType = languages[0].id;

export const getLanguageTitle = (language: LanguageType): string =>
  languages.find(({ id }) => id === language)?.title ?? null;
