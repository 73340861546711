import React, { useContext } from 'react';

import { PageContext } from '../../context/PageContext';
import { PageType } from '../../queries/page';
import { ModuleSchemaName } from '../../types.sanity';
import { DialogBuilder } from '../ModuleBuilder/DialogBuilder';
import { HeroBuilder } from '../ModuleBuilder/HeroBuilder';
import { ModuleBuilder, ModuleBuilderProps } from '../ModuleBuilder/ModuleBuilder';

export const DefaultPage = (props: PageType) => {
  const { sitemapItem, locale } = useContext(PageContext);

  {
    /* if there is a module with a background as first item, pull it on top of the hero */
  }
  const FIXED_BACKGROUND_MODULES: ModuleSchemaName[] = [
    'module.locations',
    'module.joblist',
    'module.billboard',
  ];
  const firstModule = (props.modules as ModuleBuilderProps['items'])?.[0];
  const firstModuleBackground =
    firstModule?.theme?.background || firstModule?.theme?.module?.background;
  const pullFirstModuleUp =
    FIXED_BACKGROUND_MODULES.includes(props.modules?.[0]?._type) ||
    (firstModuleBackground && firstModuleBackground !== 'white');

  return (
    <>
      {props.hero ? (
        <HeroBuilder hero={props.hero} pullUp={pullFirstModuleUp} />
      ) : (
        <h1 className="sr-only">{sitemapItem?.titles?.[locale]}</h1>
      )}

      {Boolean(props?.modules?.length) && <ModuleBuilder items={props.modules} />}
      {Boolean(props?.dialogs?.length) && <DialogBuilder items={props.dialogs} />}
    </>
  );
};
