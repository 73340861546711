import dynamic from 'next/dynamic';
import React from 'react';
import { Suspense } from 'react';

import { PortableTextProps } from '../../components/content/PortableText';
import { type ArticleHeroProps } from '../../heroes/ArticleHero';
import { type HeroBasicProps } from '../../heroes/HeroBasic';
import { HeroSchemaName } from '../../types.sanity';
import ModuleErrorBoundary from './ModuleErrorBoundary';

const ArticleHero = dynamic<ArticleHeroProps>(
  () =>
    import(/* webpackChunkName: "ArticleHero" */ '../../heroes/ArticleHero') as any,
  { suspense: true },
);

const PortableText = dynamic<PortableTextProps>(
  () =>
    import(
      /* webpackChunkName: "PortableText" */ '../../components/content/PortableText'
    ) as any,
  { suspense: true },
);

const HeroBasic = dynamic<HeroBasicProps>(
  () => import(/* webpackChunkName: "HeroBasic" */ '../../heroes/HeroBasic') as any,
  { suspense: true },
);

export type HeroBuilderProps = {
  hero: HeroProps; // TODO: type all modules
};

// TODO: losing all typing here, there's no connection between queried modules and props for now
type HeroProps = any & {
  _type: HeroSchemaName;
  _key: string;
  [key: string]: any;
};

export const HeroBuilder = ({ hero, pullUp }: HeroProps) => {
  if (!hero) return null;

  return (
    <Suspense fallback={``}>
      <ModuleErrorBoundary>
        {/* Hero basic */}
        {hero._type === 'hero.basic' && (
          <HeroBasic
            key={hero._key}
            {...hero}
            text={<PortableText content={hero.text} />}
            pullUp={pullUp}
          />
        )}

        {/* Article Hero */}
        {hero._type === 'hero.article' && (
          <ArticleHero {...hero} intro={<PortableText content={hero.intro} />} />
        )}
      </ModuleErrorBoundary>
    </Suspense>
  );
};

export const HeroBuilderMemo = React.memo(HeroBuilder);
