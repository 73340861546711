import { COLORS } from './colors';

export const TRANSLATION_FIELDS = {
  all_departments: {
    description:
      'Label for option to show all departments in the job finder filter dropdown.',
  },
  all_office_locations: {
    description:
      'Label for option to show all offices in the job finder filter dropdown.',
  },
  all_countries: {
    description: 'Label for button to select all countries in the job finder.',
  },
  department: {
    description: 'Default select label in the job finder departments filter.',
  },
  full_remote: {
    description: 'Label in job listing for remote jobs.',
  },
  next_slide: {
    description: 'Label for next slide button.',
  },
  no_jobs_found: {
    description: 'Text shown when the job finder returns no results.',
  },
  office_location: {
    description: 'Default select label in the job finder offices filter.',
  },
  positions_in: {
    description:
      'Label for country selector in job finder, e.g positions in [Italy].',
  },
  previous_slide: {
    description: 'Label for previous slide button.',
  },
  read_more: {
    description: 'Used on buttons in card grids.',
  },
  read_article: {
    description: 'Used on buttons in article feeds.',
  },
  remote: {
    description: 'Label shown on the job detail page for remote jobs.',
  },
  watch_video: {
    description: 'Label on video play button.',
  },
  job_detail_sidebar_title: {
    description: 'Workable detail page sidebar title',
  },
  job_detail_title: {
    description: 'Workable detail page overview title',
  },
  job_detail_title_eyebrow: {
    description: 'Workable detail page overview title eyebrow',
  },
  job_detail_sidebar_location: {
    label: 'Workable detail page sidebar location label',
  },
  job_detail_sidebar_department: {
    label: 'Workable detail page sidebar department label',
  },
  job_detail_sidebar_job_function: {
    label: 'Workable detail page sidebar job label',
  },
  job_detail_sidebar_education_level: {
    label: 'Workable detail page sidebar education label',
  },
  job_detail_sidebar_experience_required: {
    label: 'Workable detail page sidebar experience label',
  },
  job_detail_sidebar_employment: {
    label: 'Workable detail page sidebar employment label',
  },
  job_finder_show_more: {
    label: 'Label for button to load more jobs',
  },
  job_finder_clear_filters: {
    label: 'Label for button to clear filters',
  },
  job_list_no_jobs: {
    label: 'Label for button to clear filters',
  },
  video_cookies_message: {
    label: 'Text replacing Youtube videos when missing marketing cookies consent',
  },
  cookie_preferences_open_button: {
    label: 'Text to open cookie preferences',
  },
  download_list_button: {
    label: 'Label for read / download button in file download list',
  },
  download_list_show_more: {
    label: 'Label for button to load more downloads',
  },
  article_hero_contact: {
    label: 'Article label above contacts',
  },
  article_hero_language_switch: {
    label: 'Article label above language switch',
  },
  article_feed_categories_label: {
    description: 'Label for the tags filter',
  },
  article_feed_countries_label: {
    description: 'Label for the countries filter',
  },
  article_feed_load_more_label: {
    description: 'Label for the load more button',
  },
  article_feed_search_label: {
    description: 'Label for the search button',
  },
  article_feed_reset_label: {
    description: 'Label for the reset button',
  },
  article_feed_select_all_countries: {
    description: 'Select none label for the select countries select box',
  },
  article_feed_select_all_categories: {
    description: 'Select none label for the select categories select box',
  },
  article_feed_no_results_title: {
    description: 'Title to display when there are no results',
  },
  article_feed_no_results_text: {
    description: 'Text to display when there are no results',
  },
  copied_clipboard: {
    description: 'Copied to clipboard message',
  },
};

export type TranslationFieldType = keyof typeof TRANSLATION_FIELDS;

export const ICONS = {
  'external-link': 'external-link.svg',
  'flag-italy': 'flag_italy.svg',
  'flag-spain': 'flag_spain.svg',
  'flag-uk': 'flag_uk.svg',
  'magnifying-glass': 'magnifying-glass.svg',
  'map-marker': 'map-marker.svg',
  arrow: 'arrow.svg',
  chevron: 'chevron.svg',
  close: 'close.svg',
  demo: 'demo.svg',
  download: 'download.svg',
  facebook: 'facebook.svg',
  info: 'info.svg',
  instagram: 'instagram.svg',
  linkedin: 'linkedin.svg',
  lock: 'lock.svg',
  menu: 'menu.svg',
  pause: 'pause.svg',
  play: 'play.svg',
  quote: 'quote.svg',
  remote: 'remote.svg',
  trash: 'trash.svg',
  twitter: 'twitter.svg',
  youtube: 'youtube.svg',
  mail: 'mail.svg',
  link: 'link.svg',
};

export type IconType = keyof typeof ICONS;

export type ColorType = keyof typeof COLORS;

export const FONTS = {
  sans: 'Sans',
  heading: 'Heading',
  mono: 'Mono',
};

export type TextElement =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'span'
  | 'div'
  | 'p'
  | 'figcaption'
  | 'strong'
  | 'cite'
  | 'blockquote';

export type ImageType = {
  src: string;
  preventResize?: boolean;
  width?: number;
  height?: number;
  alt?: string;
  caption?: string;
  crop?: {
    _type: 'sanity.imageCrop';
    bottom: number;
    left: number;
    right: number;
    top: number;
  };
  hotspot?: {
    _type: 'sanity.imageHotspot';
    height: number;
    width: number;
    x: number;
    y: number;
  };
  downloadable?: boolean;
  zoomable?: boolean;
};

export const VIDEO_PROVIDERS = {
  youtube: 'Youtube',
  vimeo: 'Vimeo',
  cloudinary: 'Cloudinary',
  mux: 'Mux',
  sanity: 'Sanity',
  static: 'Static',
};

export type VideoProviderType = keyof typeof VIDEO_PROVIDERS;

export type VideoType = {
  loop?: boolean;
  caption?: string;
  autoPlay?: boolean;
  provider?: VideoProviderType;
  videoId?: string;
  src?: string;
  frameless?: boolean;
  poster?: ImageType;
};

export const SIZES = {
  none: 'None',
  '2xs': 'XXS',
  xs: 'Extra small',
  sm: 'Small',
  md: 'Medium',
  lg: 'Large',
  xl: 'Extra large',
  '2xl': '2XL',
  '3xl': '3XL',
  '4xl': '4XL',
};

export type SizeType = keyof typeof SIZES;
export type SizesType = { [key in keyof typeof SIZES]: string };

export const FONT_WEIGHTS = {
  light: 'Light',
  book: 'Book',
  bold: 'bold',
};

export type FontWeightType = keyof typeof FONT_WEIGHTS;
export type FontWeightsType = { [key in keyof typeof FONT_WEIGHTS]: string };

export const ALIGNMENTS = {
  left: 'Left',
  center: 'Center',
  right: 'Right',
  auto: 'Auto',
  top: 'Top',
  middle: 'Middle',
  bottom: 'Bottom',
  insideLeft: 'Inside left',
  insideRight: 'Inside right',
};
export type AlignmentType = keyof typeof ALIGNMENTS;
export type AlignmentsType = { [key in keyof typeof ALIGNMENTS]: string };

export const ORIENTATION = {
  landscape: 'Landscape',
  portrait: 'Portrait',
};
export type OrientationType = keyof typeof ORIENTATION;
export type OrientationsType = { [key in keyof typeof ORIENTATION]: string };

export const RATIOS = {
  auto: 'Auto',
  '1/1': 'Square',
  '16/9': '16/9',
  '3/2': '3/2',
  '2/1': 'Flat',
  '13/8': '13/8',
  '4/3': '4/3',
  '21/9': '21/9',
  '19/27': '19/27',
};

export type RatioType = keyof typeof RATIOS;
export type RatiosType = { [key in keyof typeof RATIOS]: string };

export const STATIC_FORMS = {
  'newsletter-sign-up': 'Newsletter sign up',
  'workable-job-application': 'Workable Job Application',
};

export type StaticFormType = keyof typeof STATIC_FORMS;
export type StaticFormsType = { [key in keyof typeof STATIC_FORMS]: string };

export const STATIC_FORM_OPTIONS = {};

export type StaticFormOptionType = keyof typeof STATIC_FORM_OPTIONS;
export type StaticFormOptionsType = {
  [key in keyof typeof STATIC_FORM_OPTIONS]?: string;
};

export type SuccessOrErrorMessage = { success: string } | { error: string };

export type WorkableJobType = {
  id?: string;
  slug?: { current?: string };
  title?: string;
  full_title?: string;
  shortcode?: string;
  code?: string;
  state?: 'draft' | 'published' | 'closed' | 'archived';
  department?: string;
  url?: string;
  application_url?: string;
  shortlink?: string;
  location?: {
    country?: string;
    country_code?: string;
    region?: string;
    region_code?: string;
    city?: string;
    zip_code?: string;
    telecommuting?: boolean;
  };
  created_at?: string;
  full_description?: string;
  description?: string;
  requirements?: string;
  benefits?: string;
  employment_type?: string;
  industry?: string;
  function?: string;
  experience?: string;
  education?: string;
  keywords?: string[];
};

export type WorkableJobsType = WorkableJobType[];

export type WorkableListItemType = Pick<
  WorkableJobType,
  'title' | 'slug' | 'location' | 'shortcode' | 'department' | 'created_at'
>;

// https://workable.readme.io/reference/apiaccountssubdomaindepartments
export type WorkableDepartmentType = {
  name?: string;
  count?: number;
  url?: string;
};

// https://workable.readme.io/reference/apiaccountssubdomainlocations
export type WorkableLocationType = {
  code?: string;
  name?: string;
  count?: number;
  url?: string;
};

export type PersonType = {
  name?: string;
  position?: string;
  description?: string;
  image?: ImageType;
  email?: string;
  phone?: string;
};

export const OTHER_COUNTRY_CODE = 'other-country';
export type OtherCountryCodeType = typeof OTHER_COUNTRY_CODE;

export type TagType = {
  title?: string;
  slug?: string;
};

export type CountryType = {
  title?: string;
  slug?: string;
};
